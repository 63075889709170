import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 20px;
  background-color: #0056b3;
  color: #ffffff;
  text-align: center;
`;

const Footer = () => (
  <FooterContainer>
    <p>© 2024 Salam Adult Family Home. All rights reserved.</p>
    <p>Call us: <a href="tel:+14253011326" style={{ color: '#ffffff' }}>+1 (425) 301-1326</a></p> {/* Add your phone number here */}
  </FooterContainer>
);

export default Footer;
