import React from 'react';
import styled from 'styled-components';

const TestimonialsContainer = styled.section`
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
`;

const TestimonialsTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #0056b3;
`;

const Testimonial = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TestimonialText = styled.p`
  font-size: 1em;
  font-style: italic;
  color: #333;
`;

const TestimonialAuthor = styled.p`
  font-size: 1em;
  margin-top: 10px;
  font-weight: bold;
  color: #666;
`;

const Testimonials = () => (
  <TestimonialsContainer id="testimonials">
    <TestimonialsTitle>What Our Clients Say</TestimonialsTitle>
    <Testimonial>
      <TestimonialText>"The caregivers are kind and attentive. My mother is happier and healthier."</TestimonialText>
      <TestimonialAuthor>- Sarah J.</TestimonialAuthor>
    </Testimonial>
    <Testimonial>
      <TestimonialText>"Excellent service! They truly care about their clients."</TestimonialText>
      <TestimonialAuthor>- John D.</TestimonialAuthor>
    </Testimonial>
    <Testimonial>
      <TestimonialText>"I am so grateful for the wonderful care provided to my father. It has made such a positive difference."</TestimonialText>
      <TestimonialAuthor>- Emily R.</TestimonialAuthor>
    </Testimonial>
    <Testimonial>
      <TestimonialText>"The staff is professional and compassionate. I highly recommend their services."</TestimonialText>
      <TestimonialAuthor>- Michael K.</TestimonialAuthor>
    </Testimonial>
    <Testimonial>
      <TestimonialText>"Our family has peace of mind knowing that our grandmother is well taken care of. Thank you!"</TestimonialText>
      <TestimonialAuthor>- Laura B.</TestimonialAuthor>
    </Testimonial>
  </TestimonialsContainer>
);

export default Testimonials;
