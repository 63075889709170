import React from 'react';
import styled from 'styled-components';

const ServicesContainer = styled.section`
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: center;
`;

const ServiceTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #0056b3;
`;

const ServicesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ServiceItem = styled.div`
  width: 300px;
  margin: 15px;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const ServiceItemTitle = styled.h3`
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
`;

const ServiceItemDescription = styled.p`
  font-size: 1em;
  color: #666;
`;

const Services = () => (
  <ServicesContainer id="services">
    <ServiceTitle>Our Services</ServiceTitle>
    <ServicesList>
      <ServiceItem>
        <ServiceItemTitle>Personal Care</ServiceItemTitle>
        <ServiceItemDescription>Assistance with daily activities like bathing, dressing, and grooming.</ServiceItemDescription>
      </ServiceItem>
      <ServiceItem>
        <ServiceItemTitle>Companionship</ServiceItemTitle>
        <ServiceItemDescription>Engaging activities and friendly conversation to keep seniors happy.</ServiceItemDescription>
      </ServiceItem>
      <ServiceItem>
        <ServiceItemTitle>Medication Management</ServiceItemTitle>
        <ServiceItemDescription>Ensuring medications are taken on time and as prescribed.</ServiceItemDescription>
      </ServiceItem>
    </ServicesList>
  </ServicesContainer>
);

export default Services;
