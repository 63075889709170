import React, { useState } from 'react';
import styled from 'styled-components';

const ContactContainer = styled.section`
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: center;
`;

const ContactTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #0056b3;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const Contact = () => {
  const [status, setStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const response = await fetch('/contact.php', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      setStatus('Thank you! Your message has been sent.');
      form.reset();
    } else {
      setStatus('Oops! There was a problem with your submission.');
    }
  };

  return (
    <ContactContainer id="contact">
      <ContactTitle>Contact Us</ContactTitle>
      <ContactForm onSubmit={handleSubmit}>
        <Input type="text" name="name" placeholder="Your Name" required />
        <Input type="email" name="email" placeholder="Your Email" required />
        <TextArea name="message" rows="5" placeholder="Your Message" required />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </ContactForm>
      <p>{status}</p>
    </ContactContainer>
  );
};

export default Contact;
