import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px 50px;
  background-color: #f0f8ff;
  color: #333;
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 20px auto;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 650px;
  object-fit: cover;
  border-radius: 10px;
`;

const HeroText = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const HeroSubText = styled.p`
  font-size: 1.2em;
`;

const HeroSection = () => (
  <HeroContainer>
    <HeroText>Welcome to Salam Adult Family Home</HeroText>
    <HeroSubText>Providing compassionate care for your loved ones</HeroSubText>
    <CarouselContainer>
      <Carousel showThumbs={false} autoPlay infiniteLoop>
        <div>
          <CarouselImage src={`${process.env.PUBLIC_URL}/Yard.jpg`} alt="Beautiful senior care home" />
        </div>
        <div>
          <CarouselImage src={`${process.env.PUBLIC_URL}/Frontyard.png`} alt="Modern senior care facility" />
        </div>
        {/* <div>
          <CarouselImage src={`${process.env.PUBLIC_URL}/home3.png`} alt="Charming senior care home" />
        </div> */}
      </Carousel>
    </CarouselContainer>
  </HeroContainer>
);

export default HeroSection;
