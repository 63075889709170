import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 20px;
  background-color: #0056b3; // Distinctive color for the nav background
  border-bottom: 1px solid #004494; // Slightly darker border for contrast
  max-height: 80px;
  padding-left: 0; // Remove left padding
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0px; // Add some padding directly to the logo if needed
`;

const Logo = styled.img`
  max-width: 100px; // Adjust the max-width to ensure the logo fits within the header
  height: auto;

  @media (max-width: 768px) {
    max-width: 100px; // Adjust max-width for smaller screens
    padding-left: 1px; // Adjust padding for alignment on smaller screens
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  a {
    margin: 0 15px;
    text-decoration: none;
    color: #ffffff; // White text for good contrast
    font-size: 18px;
    line-height: 1.5;

    &:hover {
      color: #ffdd57; // Hover color for links
    }
  }
`;

const PhoneNumber = styled.div`
  color: #ffffff;
  font-size: 18px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #ffdd57;
    }
  }
`;

const MobileNavButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileNav = styled.nav`
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: #0056b3;
  padding: 5px 0; // Add more padding to ensure links are properly spaced

  @media (max-width: 768px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
  }

  a {
    margin: 5px 0; // Add more margin to ensure links are properly spaced
    text-decoration: none;
    color: #ffffff; // White text for good contrast
    font-size: 18px;
    line-height: 1.01;

    &:hover {
      color: #ffdd57; // Hover color for links
    }
  }
`;



const Header = () => {
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <Logo src={`${process.env.PUBLIC_URL}/logo.png`} alt="Salam Adult Family Care" />
      </LogoContainer>
      <Nav>
        <a href="#services">Services</a>
        <a href="#testimonials">Testimonials</a>
        <a href="#contact">Contact</a>
        <a href="#location">Location</a>
      </Nav>
      <PhoneNumber>
        Call us: <a href="tel:+1234567890">+1 (425) 301-1326</a> {/* Add your phone number here */}
      </PhoneNumber>
      <MobileNavButton onClick={toggleMobileNav}>
        &#9776;
      </MobileNavButton>
      <MobileNav open={isMobileNavOpen}>
        <a href="#services" onClick={toggleMobileNav}>Services</a>
        <a href="#testimonials" onClick={toggleMobileNav}>Testimonials</a>
        <a href="#contact" onClick={toggleMobileNav}>Contact</a>
        <a href="#location" onClick={toggleMobileNav}>Location</a>
      </MobileNav>
    </HeaderContainer>
  );
};

export default Header;